export const BookmarkUnselectedIcon: React.FC<{ disabled?: boolean }> = (props) => {
    return <>
        <div style={{ opacity: props.disabled ? 0.5 : 1 }}>
            <svg width="24px" height="24px" viewBox="-4 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-Set" transform="translate(-417.000000, -151.000000)" fill="#000000">
                        <path d="M437,177 C437,178.104 436.104,179 435,179 L428,172 L421,179 C419.896,179 419,178.104 419,177 L419,155 C419,153.896 419.896,153 421,153 L435,153 C436.104,153 437,153.896 437,155 L437,177 L437,177 Z M435,151 L421,151 C418.791,151 417,152.791 417,155 L417,177 C417,179.209 418.791,181 421,181 L428,174 L435,181 C437.209,181 439,179.209 439,177 L439,155 C439,152.791 437.209,151 435,151 L435,151 Z" id="bookmark" >

                        </path>
                    </g>
                </g>
            </svg>
        </div>
    </>

}

export const BookmarkSelectedIcon: React.FC<{ disabled?: boolean }> = (props) => {
    return <>
        <div style={{ opacity: props.disabled ? 0.5 : 1 }}>
            <svg width="24px" height="24px" viewBox="-4 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
                    <g id="Icon-Set-Filled" transform="translate(-419.000000, -153.000000)" fill="#000000">
                        <path d="M437,153 L423,153 C420.791,153 419,154.791 419,157 L419,179 C419,181.209 420.791,183 423,183 L430,176 L437,183 C439.209,183 441,181.209 441,179 L441,157 C441,154.791 439.209,153 437,153" id="bookmark" >

                        </path>
                    </g>
                </g>
            </svg>
        </div>
    </>

}
