import { useContext, useEffect, useState } from "react"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { useQuery } from "@tanstack/react-query"
import { ContentTile } from "../Components/ModeTile"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { EnrichedPostFeedResponse, PostFeedResponse } from "../api"
import { AppCallbacks, TextStyle } from "../App"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { AnnotatedTextDisplay, SentenceDisplay } from "../Displays/TextStructureDisplay"
import { ExampleSentenceDisplay } from "../Components/ItemExamplesView"
import styled from "styled-components"
import { BookmarkSelectedIcon, BookmarkUnselectedIcon } from "../Icons/BookmarkIcon"
import { Data } from "../Types/Interfaces"


enum FeedMode {
    Feed,
    Bookmarks
}

export const FeedView: React.FC<AppCallbacks> = (props) => {

    const [mode, setMode] = useState(FeedMode.Feed)


    const FeedMenuConfig = [{
        Label: "Feed",
        OnClick: () => { setMode(FeedMode.Feed) }
    },
    {
        Label: "Bookmarks",
        OnClick: () => { setMode(FeedMode.Bookmarks) }
    },
    {
        Label: "Exit",
        OnClick: () => { props.OnExit() }
    }]

    useEffect(() => {
        props.SetMenu(FeedMenuConfig)
    }, [])

    return <>
        {mode == FeedMode.Feed && <FeedLoader />}
        {mode == FeedMode.Bookmarks && <BookmarksLoader />}
    </>
}


export const BookmarksLoader: React.FC = (props) => {
    const api = useContext(ApiContext)

    const { data, error, isLoading } = useQuery({
        queryKey: ["feedPosts"],
        queryFn: () => api.GetBookmarks().then(UnwrapApiCall)
    })

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>
    }

    return <>
        <PostView posts={data ?? []} />
    </>
}

export const FeedLoader: React.FC = (props) => {
    const api = useContext(ApiContext)

    const { data, error, isLoading } = useQuery({
        queryKey: ["feedPosts"],
        queryFn: () => api.FeedPosts().then(UnwrapApiCall)
    })

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>
    }

    return <>
        <PostView posts={data ?? []} />
    </>
}

export const PostView: React.FC<{ posts: EnrichedPostFeedResponse[] }> = (props) => {
    const api = useContext(ApiContext)
    return <>
        {props.posts.map((post: EnrichedPostFeedResponse) => (<>
            <ContentTile>
                <PostContainer>

                    <MetadataContainer>
                        <img style={{ display: 'block', borderRadius: '10px' }} crossOrigin="anonymous" src={`${api.Settings?.ApiServer}/feed/avatar/${post.profileUuid}.png`} height={100} width={100} />
                        <ColumnLayout style={{ alignItems: 'center', width: 'auto' }}>
                            <TextStyle weight='bold' size={1}>{post.handle}</TextStyle>
                            <TextStyle style={{ fontStyle: 'italic' }} size={1}>{post.createdAt.toLocaleString()}</TextStyle>
                            <RowLayout style={{ justifyContent: 'center' }}>
                                <TextStyle size={0.75}>{(post.progress.progress * 100).toFixed(2)}% ({post.progress.known}/{post.segments.length})</TextStyle>
                            </RowLayout>
                            <BookmarkPostInterface postUuid={post.uuid} />
                        </ColumnLayout>
                    </MetadataContainer>
                    <ContentContainer>
                        <ExampleSentenceDisplay text={post.content} sentenceRef={''} segments={post.segments} />
                    </ContentContainer>
                </PostContainer>
            </ContentTile>
        </>))}
    </>
}

export const BookmarkPostInterface: React.FC<{ postUuid: string }> = (props) => {
    const api = useContext(ApiContext)

    const [refreshToken, setRefreshToken] = useState(0)

    const { data, error, isLoading } = useQuery({
        queryKey: ["bookmarkPost", props.postUuid, refreshToken],
        queryFn: () => api.CheckBookmark(props.postUuid).then(UnwrapApiCall)
    })


    const toggleBookmark = () => {
        console.log("toggleBookmark", props.postUuid)
        api.ToggleBookmark({ postUuid: props.postUuid }).then(UnwrapApiCall).then(res => {
            setRefreshToken(refreshToken + 1)
        })
    }


    if (isLoading) {
        return <BookmarkUnselectedIcon disabled={true} />
    }

    if (error) {
        return <BookmarkUnselectedIcon disabled={true} />
    }

    let isBookmarked: boolean = data?.isBookmarked ?? false

    return <>
        <div onClick={toggleBookmark}>
            {isBookmarked ? <BookmarkSelectedIcon /> : <BookmarkUnselectedIcon />}
        </div>

    </>
}

export const PostContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`

export const MetadataContainer = styled.div`
    @media (width > 768px) {
        display: flex;
        flex-direction: row;
        width: calc(20% - 12px);
        gap: 24px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    @media (width < 768px) {
        display: flex;
        flex-direction: row;
        gap: 12px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`

export const ContentContainer = styled.div`
    @media (width > 768px) {
    display: flex;
    flex-direction: column;
    width: calc(80% - 12px);
    gap: 24px;
    justify-content: center;
    align-items: center;
    }
    @media (width < 768px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`


export const SegmentsForTextLoader: React.FC<{ text: string }> = (props) => {
    const api = useContext(ApiContext)

    const { data, error, isLoading } = useQuery({
        queryKey: ["segmentsForText", props.text],
        queryFn: () => api.BreakdownText({ text: props.text }).then(UnwrapApiCall)
    })

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>
    }

    return <>

    </>
}