import { useState } from "react"
import { TextStyle } from "../App"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { SentenceKeyLastSeen } from "../api"
import { ReviewMistakesLoader } from "../Loaders/ReviewMistakesLoader"
import { RoundControllerParams } from "./NewRoundView"
import { Data, OnExit } from "../Types/Interfaces"
import { SetMenu } from "../Loaders/LanguageDefinitionLoader"
import { LanguageConfiguration } from "../Config/LanguageDefinitionConfiguration"
import { StyledButton } from "../Components/ModeTile"
import { IntroduceRelatedWordsLoader } from "../Loaders/IntroduceRelatedWordsLoader"

export const IntroduceRelatedWordsView: React.FC<
    Data<{ Params: RoundControllerParams, LanguageDefinitionConfiguration: LanguageConfiguration<any> }> &
    OnExit &
    SetMenu
> = (props) => {
    enum ViewState {
        Options,
        Review
    }

    const [viewState, setViewState] = useState(ViewState.Options)
    const [seen, setSeen] = useState<SentenceKeyLastSeen>(SentenceKeyLastSeen.ThisPeriod)

    const UpdateParameters = () => {

    }

    return (
        <ColumnLayout>
            {viewState == ViewState.Options && <>
                <RowLayout>
                    <TextStyle weight="bold">Seen</TextStyle>
                    <select
                        value={seen}
                        onChange={(e) => setSeen(e.target.value as SentenceKeyLastSeen)}
                        className="p-2 border rounded"
                    >
                        {Object.values(SentenceKeyLastSeen).map(value => (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        ))}
                    </select>
                </RowLayout>
                <StyledButton onClick={() => { setViewState(ViewState.Review) }}>
                    Review
                </StyledButton>

            </>}
            {viewState === ViewState.Review && <>
                <IntroduceRelatedWordsLoader
                    Data={{ ...props.Data.Params }}
                    SetMenu={props.SetMenu}
                    OnExit={props.OnExit}
                    parameters={{
                        seen: seen,
                    }}
                />
            </>
            }
        </ColumnLayout >
    )
}