import { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { TextStyle } from "../App"
import { Data } from "../Types/Interfaces"
import { ContentTile, StyledButton } from "./ModeTile"
import { RoundItemData } from "../Views/RoundView"
import { LanguageDefinition, LanguageUnitDefinition } from "../Types/LanguageDefinition"
import { LanguageConfiguration, ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration"
import { ZhBaseUnitTestDefinition } from "../Types/Zh/LanguageDefinition"
import { ZhBaseUnitType } from "./TestFolds/ZhBaseUnit"
import { ExtraUnitsDefinitionType } from "../Loaders/LanguageDefinitionLoader"
import { TextRefMap } from "../Database/TextStore"
import { LanguageFundamentalType } from "../Database/HistoryState"
import { ZhCompositeUnitType } from "./TestFolds/ZhCompositeUnit"
import { ItemData, ItemHistory, ItemRating } from "../api"
import { ItemTypeTestConfigurationMap } from "../Config/Language/Zh/Types"
import { TestDefinition, UnitTestInterfaceConfiguration } from "../Config/Language/Zh/Config/UnitTestInterfaceConfig"
import { NewRoundItemData } from "../Views/NewRoundView"
import { Modal } from "./Modal"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { PracticeItemView } from "../Views/PracticeView"
import { ItemExamplesView } from "./ItemExamplesView"
import React from "react"
import { LoadDictionaryQuery } from "../Views/DictionaryView"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { useQuery } from "@tanstack/react-query"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { fsrs, Rating, RecordLog } from "ts-fsrs"
import dayjs from "dayjs"

interface NewDisplayItemProps {

    ItemDataRecord: Record<string, ItemData>
    ItemTypeTests: ItemTypeTestConfigurationMap
    UnitTestInterfaces: UnitTestInterfaceConfiguration
    // LanguageConfiguration: LanguageConfiguration<any>
    // ExtraUnitsDefinition: ExtraUnitsDefinitionType
    // TextRefMap: TextRefMap
    Introduce: boolean,
    RoundItem: NewRoundItemData,

    TimeLimit: number,

    OnIntroduced: () => any
    OnAnswer: (correct: boolean, timeElapsed: number, rating?: ItemRating) => any

}

export const DisplayItemContentSpace = styled.div`
flex-basis: 40%;
justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 12px;

`
export const NewDisplayItem: React.FC<Data<NewDisplayItemProps>> = props => {
    const [revealFold, setRevealFold] = useState(false)

    const [answerSet, setAnswerSet] = useState(false)

    const [practiceView, setPracticeView] = useState(false)
    const [examplesView, setExamplesView] = useState(false)
    const [dictionaryView, setDictionaryView] = useState(false)
    const [ratingView, setRatingView] = useState(false)

    const onAnswer = (correct: boolean, rating?: ItemRating) => {
        setRevealFold(false)
        props.Data.OnAnswer(correct, timeElapsed, rating)
        setTimeElapsed(0)
        setStartTime(new Date())
        setTimer(conditionallySetTimer)
    }

    const ItemDetails: {
        TestDefinition: TestDefinition | undefined
        Content: ItemData | undefined
    } = {
        TestDefinition: undefined,
        Content: undefined
    }

    ItemDetails.TestDefinition = props.Data.UnitTestInterfaces[props.Data.RoundItem.UnitId][props.Data.RoundItem.TestDefinition]
    ItemDetails.Content = props.Data.ItemDataRecord[props.Data.RoundItem.ItemKey]

    // if (props.Data.RoundItem.ItemFundamentalType == LanguageFundamentalType.Base) {

    //     ItemDetails.TestDefinition = props.Data.LanguageConfiguration.BaseUnitTestDefinitions[props.Data.RoundItem.TestDefinition]
    //     ItemDetails.LanguageUnitDefinition = SelectLanguageDefinitionUnit(props.Data.RoundItem.UnitItemKey, props.Data.LanguageConfiguration.Definition, props.Data.ExtraUnitsDefinition)

    //     if (ItemDetails.LanguageUnitDefinition == undefined) {
    //         throw new Error(`language definition unit key ${props.Data.RoundItem.UnitItemKey} not found`)
    //     }

    //     ItemDetails.Content = {
    //         Id: props.Data.RoundItem.UnitItemKey, LanguageUnitDefinition: ItemDetails.LanguageUnitDefinition!!
    //     }

    // } else if (props.Data.RoundItem.ItemFundamentalType == LanguageFundamentalType.Composite) {

    //     ItemDetails.TestDefinition = props.Data.LanguageConfiguration.CompositeUnitTestDefinitions[props.Data.RoundItem.TestDefinition]

    //     const textLookup = props.Data.TextRefMap[props.Data.RoundItem.UnitItemKey]

    //     ItemDetails.Content = {
    //         Id: props.Data.RoundItem.UnitItemKey, Primary: textLookup.Primary ? textLookup.Primary : "", Target: textLookup.Target
    //     }

    // } else {
    //     throw new Error("unknown item type found in displayitem")
    // }

    const [startTime, setStartTime] = useState(new Date())
    const [timeElapsed, setTimeElapsed] = useState(0)

    const bottomScreenRef = React.createRef<HTMLDivElement>()

    useEffect(() => {
        if (revealFold) {
            if (bottomScreenRef.current) {
                bottomScreenRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [revealFold, bottomScreenRef]);

    const conditionallySetTimer = () => {
        if (!revealFold && !props.Data.Introduce && props.Data.TimeLimit > 0) {
            return setTimeout(() => { console.log("time limit reached"); onResult(); }, props.Data.TimeLimit * 1000)
        } else {
            return undefined
        }
    }

    const [timer, setTimer] = useState<any>(conditionallySetTimer())

    const onResult = () => {
        clearTimeout(timer)

        var endTime = new Date()

        setTimeElapsed(endTime.getTime() - startTime.getTime())
        setRevealFold(true)
    }

    const [receivedAnswer, setReceivedAnswer] = useState<boolean | undefined>(undefined)

    const ReceiveAnswer = (answer: boolean) => {
        setRevealFold(true)
        setReceivedAnswer(answer)

        onResult()
    }

    const StartPractice = () => {
        setPracticeView(true)
    }

    const ShowExamples = () => {
        setExamplesView(true)
    }

    const ShowDictionary = () => {
        setDictionaryView(true)
    }

    const UserIndicatesIfCorrect = ItemDetails.TestDefinition!!.UserIndicatesIfCorrect(props.Data.RoundItem)

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (!revealFold) {
                if (event.key == '`') {
                    onResult()
                } else {
                    return
                }
            } else {

                if (event.code == 'Space') {
                    if (!UserIndicatesIfCorrect && receivedAnswer !== undefined) {
                        onAnswer(receivedAnswer);
                    } else {
                        onAnswer(true)
                    }
                } else {
                    switch (event.key.toLowerCase()) {
                        case '1':
                            // Again
                            props.Data.OnAnswer(false, timeElapsed, ItemRating.Again);
                            break;
                        case '2':
                            // Hard
                            props.Data.OnAnswer(true, timeElapsed, ItemRating.Hard);
                            break;
                        case '3':
                            // Good
                            props.Data.OnAnswer(true, timeElapsed, ItemRating.Good);
                            break;
                        case '4':
                            // Easy
                            props.Data.OnAnswer(true, timeElapsed, ItemRating.Easy);
                            break;
                        case 'x':
                            // Examples
                            ShowExamples();
                            break;
                        case 'd':
                            // Dictionary
                            ShowDictionary();
                            break;
                    }
                }
            }






        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [revealFold, receivedAnswer, UserIndicatesIfCorrect]);

    return (
        <>
            {practiceView && <>
                <Modal OnClose={() => { setPracticeView(false) }}>

                    <PracticeItemView Data={{ Item: ItemDetails.Content, TestDefinitions: props.Data.UnitTestInterfaces[props.Data.RoundItem.UnitId] }} />
                </Modal>
            </>}

            {examplesView && <>
                <Modal OnClose={() => { setExamplesView(false) }}>
                    <ItemExamplesView Data={{ Item: ItemDetails.Content }} />
                </Modal>
            </>}
            {dictionaryView && <>
                <Modal OnClose={() => { setDictionaryView(false) }}>
                    <LoadDictionaryQuery query={props.Data.RoundItem.ItemKey} />
                </Modal>
            </>}

            {ratingView && <>
                <Modal OnClose={() => { setRatingView(false) }}>
                    <SelectRatingView OnSelect={onAnswer} ItemHistoryId={props.Data.RoundItem.ItemHistoryId} />
                </Modal>
            </>}

            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>

                <DisplayItemContentSpace>
                    {ItemDetails.TestDefinition!!.AboveFold({ Data: ItemDetails.Content, OnAnswer: ReceiveAnswer, ShowAnswerInterface: (!props.Data.Introduce && (!revealFold && !UserIndicatesIfCorrect)) })}
                </DisplayItemContentSpace>
                <hr />
                <DisplayItemContentSpace>
                    {props.Data.Introduce && <>
                        {ItemDetails.TestDefinition!!.BelowFold({ Data: ItemDetails.Content, Revealed: revealFold })}
                    </>}
                    {!props.Data.Introduce && <>
                        {<div style={{ display: revealFold ? 'flex' : 'none' }}>

                            {ItemDetails.TestDefinition!!.BelowFold({ Data: ItemDetails.Content, Revealed: revealFold })}

                        </div>}
                    </>}


                </DisplayItemContentSpace>
                <div style={{ marginBottom: '0', marginTop: 'auto' }}>
                    {props.Data.Introduce && <div style={{ padding: 24, gap: 24, display: 'flex', flexDirection: 'column' }}>
                        <RowLayout style={{ justifyContent: 'center' }}>
                            <StyledButton onClick={ShowExamples}><TextStyle case='uppercase' size={2} weight='bold'>Examples</TextStyle></StyledButton >
                            <StyledButton onClick={ShowDictionary}><TextStyle case='uppercase' size={2} weight='bold'>Dictionary</TextStyle></StyledButton >
                            <StyledButton onClick={StartPractice}><TextStyle case='uppercase' size={2} weight='bold'>Practice</TextStyle></StyledButton >
                        </RowLayout>
                        <RowLayout style={{ justifyContent: 'center' }}>
                            <StyledButton onClick={props.Data.OnIntroduced}><TextStyle case='uppercase' size={2} weight='bold'>Next</TextStyle></StyledButton >
                        </RowLayout>
                    </div>}
                    {!revealFold && !props.Data.Introduce && UserIndicatesIfCorrect && <div style={{ padding: 24 }}>
                        <StyledButton style={{ width: '100%' }} onClick={onResult}><TextStyle case='uppercase' size={2} weight='bold'>Reveal</TextStyle></StyledButton>
                    </div>}
                    {revealFold && !props.Data.Introduce && <div style={{ marginBottom: 0, marginTop: 'auto', alignItems: 'center', justifyContent: 'center', }}>
                        <TextStyle style={{ padding: 12 }} align='center'>Time elasped: {timeElapsed}ms</TextStyle>

                        {!UserIndicatesIfCorrect && <div style={{ padding: 12, gap: 12, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            {receivedAnswer == true && <TextStyle>✅ Correct ✅</TextStyle>}
                            {receivedAnswer == false && <TextStyle>❌ Incorrect ❌</TextStyle>}
                        </div>}

                        <StyledButton onClick={() => { setRatingView(true) }}><TextStyle case='uppercase' size={1} weight='bold'>Set Rating</TextStyle></StyledButton >
                        {receivedAnswer == false && <StyledButton onClick={() => onAnswer(true)}><TextStyle>Override Correct</TextStyle></StyledButton>}
                        <StyledButton onClick={ShowExamples}><TextStyle case='uppercase' size={1} weight='bold'>Examples</TextStyle></StyledButton >
                        <StyledButton onClick={ShowDictionary}><TextStyle case='uppercase' size={1} weight='bold'>Dictionary</TextStyle></StyledButton >
                        {UserIndicatesIfCorrect && <div style={{ padding: 12, gap: 12, display: 'flex' }}>
                            <StyledButton onClick={() => onAnswer(false)}><TextStyle case='uppercase' size={1} weight='bold'>❌ Incorrect</TextStyle></StyledButton >
                            <StyledButton onClick={() => onAnswer(true)}><TextStyle case='uppercase' size={1} weight='bold'>✅ Correct</TextStyle></StyledButton >

                        </div>}
                        {!UserIndicatesIfCorrect && <>
                            <StyledButton onClick={() => onAnswer(receivedAnswer!!)}><TextStyle case='uppercase' size={1} weight='bold'>Continue</TextStyle></StyledButton >

                        </>}
                        <div ref={bottomScreenRef} style={{ padding: 12 }}>

                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}

export const SelectRatingView: React.FC<{ OnSelect: (correct: boolean, rating: ItemRating) => any, ItemHistoryId: number }> = props => {

    const api = useContext(ApiContext)


    const { isPending, error, data } = useQuery({
        queryKey: ['itemHistory', props.ItemHistoryId],
        queryFn: () => api.GetItemHistory(props.ItemHistoryId).then(UnwrapApiCall),
        gcTime: 0
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    const now = new Date()
    const f = fsrs()
    const h = data!!;

    const mapToCard = {
        difficulty: h.difficulty,
        due: now,
        lapses: h.lapses,
        last_review: h.lastSeen,
        reps: h.reps,
        elapsed_days: dayjs(now).diff(h.due, 'day'),
        scheduled_days: 0,
        stability: h.stability,
        state: h.learningState

    }

    const recordLog = f.repeat(mapToCard, now)

    return <ContentTile>
        <ColumnLayout >
            <SelectRatingButton history={h} recordLog={recordLog} rating={ItemRating.Again} OnSelect={props.OnSelect} ItemHistoryId={props.ItemHistoryId} />
            <SelectRatingButton history={h} recordLog={recordLog} rating={ItemRating.Hard} OnSelect={props.OnSelect} ItemHistoryId={props.ItemHistoryId} />
            <SelectRatingButton history={h} recordLog={recordLog} rating={ItemRating.Good} OnSelect={props.OnSelect} ItemHistoryId={props.ItemHistoryId} />
            <SelectRatingButton history={h} recordLog={recordLog} rating={ItemRating.Easy} OnSelect={props.OnSelect} ItemHistoryId={props.ItemHistoryId} />
        </ColumnLayout>

    </ContentTile>
}


const SelectRatingButton: React.FC<{ history: ItemHistory, recordLog: RecordLog, rating: ItemRating, OnSelect: (correct: boolean, rating: ItemRating) => any, ItemHistoryId: number }> = props => {

    const rating = (() => {
        switch (props.rating) {
            case 'Again': return Rating.Again
            case 'Hard': return Rating.Hard
            case 'Good': return Rating.Good
            case 'Easy': return Rating.Easy
            default: return Rating.Again
        }
    })()

    return <>
        <StyledButton style={{ height: '100%' }} onClick={() => { props.OnSelect(false, props.rating) }}>
            <ColumnLayout style={{ gap: 0, paddingBottom: 6 }}>
                <TextStyle case='uppercase' size={1} weight='bold'>{props.rating}</TextStyle>
                <RowLayout>
                    <TextStyle weight='bold' size={0.5}>Due</TextStyle>
                    <TextStyle size={0.65}>{props.recordLog[rating].card.due.toLocaleString()}</TextStyle>
                </RowLayout>
                <RowLayout>
                    <TextStyle weight='bold' size={0.5}>Stability</TextStyle>
                    <TextStyle size={0.65}>{props.history.stability}</TextStyle>
                    <TextStyle size={0.5}>-&gt;</TextStyle>
                    <TextStyle size={0.65}>{props.recordLog[rating].card.stability}</TextStyle>
                </RowLayout>
                <RowLayout>
                    <TextStyle weight='bold' size={0.5}>Difficulty</TextStyle>
                    <TextStyle size={0.65}>{props.history.difficulty}</TextStyle>
                    <TextStyle size={0.5}>-&gt;</TextStyle>
                    <TextStyle size={0.65}>{props.recordLog[rating].card.difficulty}</TextStyle>
                </RowLayout>
            </ColumnLayout>
        </StyledButton >
    </>
}
