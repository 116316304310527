import { useQuery } from "@tanstack/react-query"
import { useContext } from "react"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { Data, OnExit } from "../Types/Interfaces"
import { SetMenu } from "./LanguageDefinitionLoader"
import { NewLearnView } from "../Views/NewLearnView"
import { ZhUnitTestInterfaceConfig } from "../Config/Language/Zh/Config/UnitTestInterfaceConfig"
import { RoundControllerParams } from "../Views/NewRoundView"
import { ZhItemTypeTestConfiguration } from "../Config/Language/Zh/Config/ItemTypeTestConfig"
import { SelectIntroduceWordsParameters, SelectMistakesParameters, SelectUnitParameters } from "../api"

export const IntroduceRelatedWordsLoader: React.FC<Data<RoundControllerParams> & OnExit & SetMenu & { parameters: SelectIntroduceWordsParameters }> = (props) => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: [`related-words`],
        queryFn: () => api.GetIntroduceRelatedWords(props.parameters).then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    const response = data!!;

    const newItems = response._new;
    const reviewItems = response.review
    const itemData = response.itemData
    
    const params = { ... props.Data }
    params.IntroduceCount = newItems.length
    params.RoundSize = newItems.length + reviewItems.length

    const nextItems = {
        New: newItems,
        Review: reviewItems
    }

    return <>
        <NewLearnView
            OnExit={props.OnExit}
            SetMenu={props.SetMenu}
            Data={{
                Params: params,
                NextUnits: nextItems,
                ItemDataRecord: itemData,
                ItemTypeTests: ZhItemTypeTestConfiguration,
                UnitTestInterfaces: ZhUnitTestInterfaceConfig,
                SendToServer: true 
            }}
        />
    </>
}